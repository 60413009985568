import { Injectable } from '@angular/core';
import { Observable, of, merge } from 'rxjs';
import { map, catchError, mergeMap, filter, take } from 'rxjs/operators';
import { VehicleService, VehicleResponse } from 'app/service/vehicle.service';
import { PersonalLinesPolicyService } from 'app/service/personal-lines-policy.service';
import { PersonalLinesPolicy } from 'app/model/rest/personal-lines-policy';
import { RatedMileageService } from 'app/service/rated-mileage.service';
import { RatedMileage } from 'app/model/rated-mileage';
import { MobilePolicyInfoService } from 'app/service/mobile-policy-info.service';
import { MobileProgram } from 'app/model/mobileprogram';
import { Vehicle } from 'app/model/vehicle';
import { TermAndConditionStatusService } from './term-and-condition-status.service';

@Injectable()
export class ProgramSelectService {
  vehicleId: number;
  connectedCarArray: any;
  // eslint-disable-next-line max-params
  constructor(
    private vehicleService: VehicleService,
    private personalLinesPolicyService: PersonalLinesPolicyService,
    private ratedMileageService: RatedMileageService,
    private mobilePolicyInfoService: MobilePolicyInfoService,
    public termAndConditionStatusService: TermAndConditionStatusService
  ) { }

  fetchProgramsByPolicyNumbers(policyNumbers: string[]): Observable<Vehicle> {
    const vehicleServiceCalls = this.assembleVehicleServiceObservablesForPolicyNumbers(policyNumbers);
    return merge(...vehicleServiceCalls)
      .pipe(mergeMap((response) => this.processVehicleServiceResponse(response)));
  }

  fetchProgramByVin(vin: string, programType: string): Observable<Vehicle> {
    return this.vehicleService.getVehicleByVin(vin, programType)
      .pipe(
        mergeMap((response) => this.processVehicleServiceResponse([response])),
        filter((vehicle) => vehicle.vin === vin),
        take(1)
      );
  }

  assembleVehicleServiceObservablesForPolicyNumbers(policyNumbers: string[]): Observable<VehicleResponse[]>[] {
    const observables = [];
    for (const policyNumber of policyNumbers) {
      const observable = this.vehicleService.getVehicleByPolicy(policyNumber);
      observables.push(observable);
    }
    return observables;
  }

  processVehicleServiceResponse(responses: VehicleResponse[]): Observable<Vehicle> {
    const outputObservables = [];
    let mobilePolicyNumber = '';
    for (const response of responses) {
      const vehicle = new Vehicle(response);
      if (vehicle.scoringModel === 'SM1' || vehicle.scoringModel === 'SM2') {
        outputObservables.push(this.prepareServiceCallsForSmartMiles(vehicle));
      } else if (vehicle.scoringModel) {
        outputObservables.push(this.finalizeSmartRideVehicle(vehicle));
      }
      if (response.isEnrolledInSRM && response.mobilePolicyNumber) {
        mobilePolicyNumber = response.mobilePolicyNumber;
      }
    }
    if (mobilePolicyNumber) {
      outputObservables.push(this.prepareServiceCallsForSmartRideMobile(mobilePolicyNumber));
    }
    // eslint-disable-next-line no-extra-parens
    return (<Observable<Vehicle>>merge(...outputObservables));
  }

  finalizeSmartRideVehicle(vehicle: Vehicle): Observable<Vehicle> {
    vehicle.type = 'sride';
    vehicle.vehicleDataUnavailable = false;
    vehicle.ratedMileageDataUnavailable = false;
    if (!sessionStorage.getItem('isAdmin')) {
      if (vehicle.programType === 'TC') {
        return this.termAndConditionStatusService
          .getConnectedCarConsentStatusWithPolicy(vehicle.vin, vehicle.type, vehicle.policyNum).pipe(mergeMap((response) => {
              if (response[0].statusCode === 'N') {
                vehicle.acceptanceStatus = response[0].statusCode;
                sessionStorage.setItem('SmartRide ConnectedCar', 'true');
              }
              return of(vehicle);
        }));
      }
    }
    return of(vehicle);
  }

    // We may have received dummy vehicles as placeholders for mobile policies.
    // This strips them out.
    vehicleIsValid(vehicle: Vehicle): boolean {
        if (vehicle.vin) {
            return true;
        }
        if (vehicle.vid) {
            return true;
        }
        return false;
    }

  addRatedMileageToVehicleTotal(vehicle: Vehicle, ratedMileageResponse: RatedMileage[]): void {
    vehicle.ratedMileageTotal = ratedMileageResponse;
  }

  prepareServiceCallsForSmartMiles(vehicle: Vehicle): Observable<Vehicle> {
      vehicle.type = 'smiles';
      vehicle.vehicleDataUnavailable = false;
      vehicle.ratedMileageDataUnavailable = true;

      return this.personalLinesPolicyService.getPolicyData(vehicle.policyNum).pipe(mergeMap((policyResponse) => {
          const policyVehicle = this.findVehicleInPolicyResponse(policyResponse, vehicle);
          if (!policyVehicle) {
              return new Observable<Vehicle>((observer) => {
                  observer.complete();
              });
          }

          if (!sessionStorage.getItem('isAdmin')) {
            if (vehicle.vendorIdCode === 'FORD') {
              this.termAndConditionStatusService
                .getConnectedCarConsentStatusWithPolicy(vehicle.vin, vehicle.type, vehicle.policyNum)
                .subscribe({
                  next: (response) => {
                    if (response[0].statusCode === 'N') {
                        vehicle.acceptanceStatus = response[0].statusCode;
                        sessionStorage.setItem('SmartMiles ConnectedCar', 'true');
                      }
                  },
                  error: () => of(Error)
            });
            }
          }

          const modifierDetails = this.getVehicleModifierDetails(policyVehicle);
          vehicle.vehicleEnrollments = modifierDetails.vehicleEnrollment;
          vehicle.vehicleId = this.vehicleId;
          vehicle.smEnrollDate = modifierDetails.smartMiles.enrollDate;
          vehicle.enrolledMidTerm = modifierDetails.smartMiles.enrollDate > policyVehicle.periodStart;
          vehicle.annualMileage = modifierDetails.smartMiles.annualMileage;
          vehicle.typicalAnnualMileage = modifierDetails.smartMiles.typicalAnnualMileage;
          vehicle.periodStart = policyResponse.periodStart;
          vehicle.periodEnd = policyResponse.periodEnd;
          vehicle.calculateTermBreakpoints();
          const prevTermStart = new Date(vehicle.periodStart.valueOf());
          const previousSixMonths = 6;
          prevTermStart.setMonth(prevTermStart.getMonth() - previousSixMonths);
          this.ratedMileageService.getRatedMileage(
              vehicle.vin, vehicle.policyNum, prevTermStart, vehicle.periodEnd
          ).subscribe((res) => {
              this.addRatedMileageToVehicleTotal(vehicle, res);
          }, (error) => of(error));

          return this.ratedMileageService.getRatedMileage(
              vehicle.vin, vehicle.policyNum, vehicle.periodStart, vehicle.periodEnd
          ).pipe(
            map((ratedMileageResponse) => this.addRatedMileageToVehicle(vehicle, ratedMileageResponse)),
              catchError(() => {
                  vehicle.ratedMileageDataUnavailable = true;
                  return of(vehicle);
              })
          );
      }));
  }

    findVehicleInPolicyResponse(policyResponse: PersonalLinesPolicy, sreVehicle: Vehicle): any {
        if (!policyResponse?.lob?.vehicles) {
            return null;
        }

        for (const pcVehicle of policyResponse.lob.vehicles) {
            this.vehicleId = pcVehicle.fixedId;
            if (pcVehicle.vin !== sreVehicle.vin || !pcVehicle.modifiers) {
                continue;
            }

            for (const modifier of pcVehicle.modifiers) {
                if (modifier.code === 'SmartMiles') {
                    return pcVehicle;
                }
            }
            return null;
        }
    }

    getVehicleModifierDetails(vehicle): any {
        for (const modifier of vehicle.modifiers) {
            if (modifier.code === 'SmartMiles') {
                return modifier;
            }
        }
        return null;
    }

    addRatedMileageToVehicle(vehicle: Vehicle, ratedMileageResponse: RatedMileage[]): Vehicle {
        vehicle.ratedMileage = ratedMileageResponse;
        vehicle.calcTotalRatedMileage();
        vehicle.ratedMileageDataUnavailable = false;
        return vehicle;
    }

    prepareServiceCallsForSmartRideMobile(policyNumber: string): Observable<Vehicle> {
        return this.mobilePolicyInfoService.getMobileProgramData(policyNumber).pipe(map((mobileProgram: MobileProgram) => {
            const vehicle = new Vehicle();
            vehicle.enrolledInSRM = true;
            vehicle.mobilePolicy = policyNumber;
            vehicle.mobileProgram = mobileProgram;
            return vehicle;
        }));
    }

    setConnectedCarDataForLogin(connectedcarArray): void {
        this.connectedCarArray = connectedcarArray;
    }

    getConnectedCarDataForLogin(): any {
        return this.connectedCarArray;
    }
}
